.rmdp-wrapper {
  /* border: 1px solid var(--bs-primary); */
  box-shadow: 0 0 5px var(--bs-primary) !important;
}

.rmdp-panel-body li {
  background-color: var(--light-active-bg) !important;
  box-shadow: 0 0 2px var(--bs-primary) !important;
}

.rmdp-week-day {
  color: var(--bs-primary) !important;
}

.rmdp-day.rmdp-deactive {
  color: var(--bs-primary) !important;
}

.rmdp-range {
  color: var(--bs-primary) !important;
  background-color: var(--light-active-bg) !important;
  box-shadow: 0 0 3px var(--light-focus-shadow-rgb) !important;
}

.rmdp-range-hover {
  color: var(--bs-primary) !important;
  background-color: var(--light-hover-bg) !important;
}


.rmdp-arrow {
  border: solid var(--light-active-bg) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: var(--light-active-bg) !important;
  box-shadow: 0 0 3px var(--bs-primary) !important;
}

.rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--light-active-bg) !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--bs-gray-700) !important;
}

.rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--bs-primary) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  color: var(--bs-primary) !important;
  background-color: var(--light-active-bg) !important;
  box-shadow: 0 0 3px var(--light-focus-shadow-rgb) !important;
}

.rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--bs-primary) !important;
}

.rmdp-action-button {
  color: var(--light-active-bg) !important;
}

.rmdp-button:not(.rmdp-action-button) {
  background-color: var(--light-active-bg) !important;
}

.highlight-weekend {
  color: var(--bs-danger) !important;
}


.rmdp-range.start {
  color: var(--bs-white) !important;
  background-color: var(--bs-gray-700) !important;
}

.rmdp-range.end {
  color: var(--bs-white) !important;
  background-color: var(--bs-gray-700) !important;
}

.rmdp-input {
  padding: 17px 15px !important;
  padding-right: 25px !important;
  height: 50px !important;
}

.rmdp-container {
  margin: 0.5rem;
}

.time {
  min-width: 300px;
}

.dayPicker {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
}

/* .btn-bd-datePicker {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--light-color);
  --bs-btn-bg: var(--light-bg);
  --bs-btn-border-color: var(--light-border-color);
  --bs-btn-hover-color: var(--light-hover-color);
  --bs-btn-hover-bg: var(--light-hover-bg);
  --bs-btn-hover-border-color: var(--light-hover-border-color);
  --bs-btn-focus-shadow-rgb: var(--light-focus-shadow-rgb);
  --bs-btn-active-color: var(--light-active-color);
  --bs-btn-active-bg: var(--light-active-bg);
  --bs-btn-active-border-color: var(--light-active-border-color);
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.775rem;
  --bs-btn-border-radius: 0.25rem;
} */

/* .bd-btn-lg {
  --bs-btn-border-radius: .5rem;

  padding: .8125rem 2rem;
} */

.icon-calendar {
  position: absolute;
  right: 4px;
  top: 51%;
  transform: translateY(-50%);
  background-color: inherit;
  cursor: pointer;
  color: var(--bs-secondary);
  /* // font-size: 18px; */
}

.rmdp-input:focus {
  border-color: var(--light-focus-shadow-rgb);
  box-shadow: var(--light-active-shadow);
  outline: 0;
}



/* .highlight-weekend{
    color: var(--bs-red);;
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-weekend:hover {
    background-color: var(--bs-red);
  } */