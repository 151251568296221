.config .btn-light {
    --bs-btn-bg: var(--bs-white);
    --bs-btn-hover-bg: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
    --bs-btn-hover-border-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.config .rgt-header-container {
    height: 29px !important
}

.config .rgt-search-container {
    height: 23px !important
}

.config .back:hover svg {
    color: var(--bs-primary) !important;
}

.config .rgt-text-truncate.click:hover {
    text-decoration: underline;
}

.config .rgt-text-truncate.copy:hover {
    text-decoration: underline;
}

.config .copy:hover {
    text-decoration: underline;
}

.config .accordion-button.collapsed {
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.config .accordion-button:hover {
    background-color: var(--bs-accordion-active-bg);
}


.config .accordion-button:focus {
    border-color: unset !important;
    box-shadow: unset !important;
    outline: 0;
}

.config .icon {
    max-width: 100px;
    margin-left: -10px;
}

.config .accordion-body {
    padding: unset !important;
}

.config .rgt-cell.rgt-cell-isactive,
.config .rgt-cell.rgt-cell-ign_on,
.config .rgt-cell.rgt-cell-ign_off,
.config .rgt-cell.rgt-cell-alternative_ride,
.config .rgt-cell.rgt-cell-alarm,
.config .rgt-cell.rgt-cell-manipulation,
.config .rgt-cell.rgt-cell-towing,
.config .rgt-cell.rgt-cell-green_drive,
.config .rgt-cell.rgt-cell-crash,
.config .rgt-cell.rgt-cell-sos {
    justify-content: center
}

.config .rgt-cell-header-day .rgt-cell-header-inner,
.config .rgt-cell-header-isactive .rgt-cell-header-inner,
.config .rgt-cell-header-ign_on .rgt-cell-header-inner,
.config .rgt-cell-header-ign_off .rgt-cell-header-inner,
.config .rgt-cell-header-alternative_ride .rgt-cell-header-inner,
.config .rgt-cell-header-alarm .rgt-cell-header-inner,
.config .rgt-cell-header-manipulation .rgt-cell-header-inner,
.config .rgt-cell-header-towing .rgt-cell-header-inner,
.config .rgt-cell-header-green_drive .rgt-cell-header-inner,
.config .rgt-cell-header-crash .rgt-cell-header-inner,
.config .rgt-cell-header-sos .rgt-cell-header-inner {
    justify-content: center
}

.config .rgt-cell-day {
    text-align: center;
}

.range-slider {
    background: #E9ECEF !important;
}

.range-slider .range-slider__thumb {
    width: 18px !important;
    height: 18px !important;
    background: var(--bs-primary) !important;
}

.range-slider .range-slider__thumb:active {
    width: 22px !important;
    height: 22px !important;
    border: solid 3px var(--bs-secondary) !important;
}

.range-slider .range-slider__range {
    background: var(--bs-secondary) !important
}

.inpuRange {
    width: 5em !important;
}

.config.input-group {
    max-width: 9rem;
}

.fences .rgt-cell-header-inner {
    padding: 0 10px;
}


.devices-table .deactivatedDevice {
    background-color: #e9e9e9;
}

.devices-table .deactivatedDevice.rgt-row-hover {
    background-color: #d1d1d1 !important;
}

.config {
    animation: fadeIn 0.3s ease-in-out;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1500px) {
    .config .w-75 {
        width: 95% !important;
    }

    h3 {
        font-size: 1.25rem
    }
}

@media (max-width: 800px) {

    .config .w-50 {
        width: 95% !important;
    }
}