@keyframes ldio {
    0% {
      transform: rotate(0)
    }

    100% {
      transform: rotate(360deg)
    }
  }

  .ldio div {
    box-sizing: border-box !important
  }

  .ldio>div {
    position: absolute;
    width: 144px;
    height: 144px;
    top: 28px;
    left: 28px;
    border-radius: 50%;
    border: 16px solid #000;
    border-color: #333333 transparent #333333 transparent;
    animation: ldio 1s linear infinite;
  }

  .ldio>div:nth-child(1) {
    width: 108px;
    height: 108px;
    top: 46px;
    left: 46px;
    animation: ldio 1s linear infinite reverse;
  }

  .loadingio-spinner-ring {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: unset;
  }

  .ldio {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }

  .ldio div {
    box-sizing: content-box;
  }