.react-select-container {
    z-index: 3;
}

.react-select-container.wsChange {
    z-index: 0;
}


.react-select__control {
    min-height: 50px !important;
    cursor: pointer !important;
}

.selectClient .react-select__control{
    min-height: 38px !important;
}

.react-select__option {
    color: var(--light-color) !important;
    cursor: pointer !important;
}

.react-select__option--is-focused {
    color: var(--light-hover-color) !important;
    background-color: var(--light-hover-bg) !important;
    border-color: var(--light-border-color) !important;
}

.react-select__option--is-selected {
    color: var(--light-active-color) !important;
    background-color: var(--light-active-bg) !important;
    border-color: var(--light-active-border-color) !important;
}

.react-select__control--menu-is-open {
    border-color: var(--light-focus-shadow-rgb) !important;
    box-shadow: var(--light-active-shadow) !important;
    outline: 0;
}



.react-select__control--is-focused {
    border-color: var(--light-focus-shadow-rgb) !important;
    box-shadow: var(--light-active-shadow) !important;
    outline: 0;
}

/* .ridesEdit .react-select__control--is-focused {
    border-color: gray !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
} */

.react-select__input {
    min-width: 20px !important;
}

.search.react-select__single-value {
    margin-left: 10px;
    font-size: 0.90rem;
}