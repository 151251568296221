.menu .block svg {
    color: var(--bs-btn-hover-bg);
    transition: color 0.5s ease;
}

.menu .block:hover svg {
    color: var(--light-color);
}

.menu a {
    text-decoration: unset !important;
}

.menu a:hover {
    text-decoration: underline !important;
}

.menu .halfIconR {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 40% 100%);
    transform: translate(-20%, -40%) scale(2);
}

.menu .halfIconL {
    clip-path: polygon(0 0, 60% 0, 60% 100%, 0% 100%);
    transform: translate(-20%, -40%) scaleX(-1) scale(2);
}

.menu .halfIcon {
    clip-path: polygon(0 0, 60% 0, 60% 100%, 0% 100%);
}

.menu {
    transition: all 0.3s ease-in-out;
}

.menu>div {
    margin-bottom: 20px;
}

.menu-modul {
    height: 150px;
}

@media (max-width: 576px) {
    .menu-modul {
        height: 100px;
    }
}

.menu {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.menu-animation {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    opacity: 0;
}

.menu-animationR-entering,
.menu-animationR-entered {
    opacity: 1;
    transform: translateX(0);
}

.menu-animationR-exiting,
.menu-animationR-exited {
    opacity: 0;
    transform: translateX(20px);
}

.menu-animationL-entering,
.menu-animationL-entered {
    opacity: 1;
    transform: translateX(0);
}

.menu-animationL-exiting,
.menu-animationL-exited {
    opacity: 0;
    transform: translateX(-20px);
}