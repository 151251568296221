  .rgt-cell-header-sticky {
    max-height: unset !important;
  }

  .hidden {
    display: none !important;
  }

  .DeviceReportRaw .rgt-cell {
    cursor: default;
    min-height: 21px;
    font-size: 12px;
  }

  .DeviceReportRaw .rgt-cell-pinned-left,
  .rgt-cell-code {
    border-right: var(--rgt-border);
  }

  .rgt-cell-param .rgt-cell-inner {
    margin: unset;
    margin-left: 0.5rem;
  }

  .rgt-cell-time .rgt-cell-inner,
  .rgt-cell-code .rgt-cell-inner {
    margin: unset;
    text-align: center;
    padding: 0 0.5rem;
  }

  .DeviceReportRaw .rgt-text-truncate {
    cursor: default;
    white-space: unset;
  }

  .rgt-cell-header:hover {
    cursor: default;
  }

  .DeviceReportRaw .rgt-header-container {
    width: 25% !important;
  }

  .DeviceReportRaw .rgt-footer {
    border: none;
    font-weight: unset !important;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
    justify-content: space-around;
    /*var(--rgt-border-color)*/
    ;
  }

  .DeviceReportRaw .rgt-cell-header-inner .rgt-text-truncate {
    text-align: center !important;
    font-size: 0.85rem !important;
    font-weight: 500 !important;
  }


  .DeviceReportRaw .rgt-footer-right-container,
  .DeviceReportRaw .rgt-footer-items-information {
    font-size: 12px !important;
    padding: unset !important;
    height: 40px;
  }

  .rgt-footer-pagination-button.rgt-disabled-button {
    background: unset !important;
    border: 1px solid #6c757d !important;
    color: var(--bs-primary) !important;
  }

  .rgt-footer-pagination-button {
    background: var(--light-active-bg) !important;
    color: var(--bs-primary) !important;
  }

  .rgt-footer-pagination-button:hover {
    background: var(--bs-gray-700) !important;
    color: var(--bs-white) !important;
  }

  .rgt-footer-pagination-button.rgt-disabled-button:hover {
    background: unset !important;
    border: 1px solid #6c757d !important;
    color: #6c757d !important;
  }

  .DeviceReportRaw .rgt-cell-time .rgt-cell-inner {
    text-align: start !important;
  }