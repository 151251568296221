.kind-vehicle-icon {
    width: 100%;
    max-width: 500px;
    margin-bottom: auto;
    position: relative;
    /* z-index: -1; */
}

.kind-mask {
    background-repeat: no-repeat;
    background-size: contain;
    /* min-width: 150px; */
    width: 45%;
}

.kind-image {
    height: 130px;
    padding-bottom: 10px;
}