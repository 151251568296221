  .rgt-cell-header-sticky {
    max-height: unset !important;
  }

  .hidden {
    display: none !important;
  }

  /* .RidesView .rgt-cell {
    cursor: default;
  } */

  /* .RidesView .rgt-text-truncate {
    cursor: default;
  } */

  .rgt-cell-header:hover {
    cursor: default;
  }

  .RidesView .rgt-header-container {
    width: 25% !important;
  }

  .RidesView .rgt-footer-items-information {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .RidesView .rgt-cell-header-fuel .rgt-cell-header-inner,
  .RidesView .rgt-cell-header-tankTanklast .rgt-cell-header-inner,
  .RidesView .rgt-cell-header-fueling .rgt-cell-header-inner,
  .RidesView .rgt-cell-header-maxAvrg .rgt-cell-header-inner,
  .RidesView .rgt-cell-header-distance .rgt-cell-header-inner,
  .RidesView .rgt-cell-header-duration .rgt-cell-header-inner {
    justify-content: end !important;
  }

  .RidesView .rgt-cell-header-tepy .rgt-cell-header-inner {
    justify-content: center !important;
  }

  .RidesView .rgt-cell-distance .rgt-cell-inner {
    font-weight: bold
  }

  .RidesView .rgt-row-selected {
    background-color: rgba(var(--light-bg), var(--bs-bg-opacity)) !important;
  }

  .RidesView .rgt-row-hover {
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }

  .RidesView .rgt-row-selected.rideFocus {
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }

  .RidesView .rgt-clickable input {
    cursor: pointer;
  }

  /* .RidesView {
    flex-direction: column-reverse;
  } */

  .RidesView .rgt-footer {
    border: none;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity))
      /*var(--rgt-border-color)*/
    ;
  }

  /* .rgt-row-odd{
    background-color: rgb(233, 233, 233);
  } */

  /* div[data-row-index="2"] {
    background-color: red;
  } */


  .modalLoading .modal-content {
    background-color: unset;
    border: unset;
  }

  .RidesView .rgt-cell-header-stamp .rgt-text-truncate {
    cursor: pointer;
  }

  .RidesView .rgt-cell-header:active {
    cursor: grabbing !important;
  }

  .RidesView .rgt-cell-header:hover {
    cursor: grab !important;
    /* W3C standards syntax, all modern browser */
    background-color: rgb(190, 190, 190) !important;
  }

  .RidesView .rgt-cell-text .rgt-text-truncate,
  .RidesView .rgt-cell-driver_purpose .rgt-text-truncate,
  .RidesView .rgt-cell-type .rgt-text-truncate {
    font-size: 0.9rem
  }

  .ridesEdit .react-select__control {
    border-radius: 0rem 0.375rem 0.375rem 0rem;
  }

  .RidesView .weekend-highlighted {
    background-color: #e9e9e9;
  }

  .RidesView .weekend-highlighted.rgt-row-hover {
    background-color: #d1d1d1 !important;
  }

  .RidesView .rgt-row-selected.rideFocus.weekend-highlighted.rideFocus {
    background-color: #d1d1d1 !important;
  }

  .RidesView .weekend-highlighted.rgt-row-selected {
    background-color: #d1d1d1 !important;
  }

  .ridesEdit .btn-group>.btn-check:checked+.btn,
  .ridesEdit .btn-group>.btn-check:focus+.btn,
  .ridesEdit .btn-group>.btn:hover,
  .ridesEdit .btn-group>.btn:focus,
  .ridesEdit .btn-group>.btn:active,
  .ridesEdit .btn-group>.btn.active,
  .ridesEdit .btn-group-vertical>.btn-check:checked+.btn,
  .ridesEdit .btn-group-vertical>.btn-check:focus+.btn,
  .ridesEdit .btn-group-vertical>.btn:hover,
  .ridesEdit .btn-group-vertical>.btn:focus,
  .ridesEdit .btn-group-vertical>.btn:active,
  .ridesEdit .btn-group-vertical>.btn.active {
    z-index: 0;
  }