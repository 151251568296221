 /* .rgt-cell-header:hover .display-none {
  display: block
}  */

 .rgt-cell {
   cursor: pointer;
 }

 .rgt-columns-manager-wrapper {
   padding: unset !important;
 }

 .rgt-search-container {
   padding: unset !important;
   position: relative !important;
   right: 5% !important;
   /* border: var(--rgt-border); */
   padding-left: 5px !important;
   height: 18px !important;
   background-color: var(--rgt-border-color) !important;
 }

.rgt-header-container {
   position: fixed;
   z-index: 3;
   right: 50px;
   width: 15% !important;
   min-width: 120px;
   top: 0px;
   border-bottom: unset !important;
   background-color: unset !important;
   height: 24px;
 }

 .devicesGroup-table .rgt-header-container {
  position: absolute !important;
  margin-top: -28px;
}

 .rgt-search-input {
   font-size: 12px !important;
   font-weight: bold !important;
   background-color: unset !important;
   line-height: unset !important;
 }

 .rgt-cell-header-sticky {
   top: -1px !important;
   min-height: 20px !important;
   max-height: 30px !important;
   background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
 }

 /* .drag-handle-devices-view {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 16px;
} */

 .rgt-cell-header-inner {
   background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
 }

 .rgt-row-hover {
   background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
 }

 /* .rgt-cell-component {
   display: 'flex';
   align-items: 'center';
   overflow: 'hidden'
 } */

 .rgt-row-selected {
   background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
 }


 .devices-table .rgt-cell-header:hover {
   cursor: move;
   /* fallback: no `url()` support or images disabled */
   cursor: grab !important;
   /* W3C standards syntax, all modern browser */
   background-color: rgb(190, 190, 190) !important;
 }

 .devices-table .rgt-cell-header:active {
   cursor: grabbing !important;
 }

 .rgt-text-truncate {
   cursor: pointer;
 }

 .rgt-column-sort-ghost {
   z-index: -1 !important;
 }

 .rgt-search-icon {
   font-size: 16px !important;
   transform: unset !important;
   margin-bottom: 5px !important;
 }

 .rgt-columns-manager-button {
   height: 24px !important;
   width: 24 !important;
 }

 .rgt-columns-manager-button:hover,
 .rgt-columns-manager-button-active {
   background: unset !important;
 }

 .devicesFilter .accordion-body{
  padding: unset !important;
 }