.DDDchart {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.DDDchartDay {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 30px;
  margin-bottom: 15px;
  width: 100%;
}

.DDDactivity {
  border: 1px solid var(--bs-gray-400);
  border-radius: 1px 1px 0px 0px;
}

.DDDactivity:hover {
  filter: brightness(120%);
  border: 1px solid var(--bs-secondary);
}

.DDDactivity.rest {
  height: 10px;
  background-color: #B4DAFE;
}

.DDDactivity.rest.manualy {
  height: 10px;
  background-color: #84c3fd;
}

.DDDactivity.rest.unknown {
  height: 10px;
  background-color: #b4dafe4a;
}


.DDDactivity.work {
  height: 20px;
  background-color: #F9A001;
}

.DDDactivity.available {
  height: 20px;
  background-color: #f8f244;
}

.DDDactivity.driving {
  height: 30px;
  background-color: #FF5555;
}


.DDDdrivingColor {
  fill: #FF5555;
}

.DDDrestColor {
  fill: #B4DAFE;
}

.DDDworkColor {
  fill: #F9A001;
}

.DDDavailableColor {
  fill: #F0EEB3;
}

.rgt-cell-linkHover:hover {
  text-decoration: underline;
  cursor: pointer
}

.DDDViewVehicle .rgt-row-hover {
  cursor: default;
}

.DDDViewVehicle .rgt-text-truncate {
  cursor: default;
}

.DDDViewDrivers .rgt-row-hover {
  cursor: default;
}

.DDDViewDrivers .rgt-text-truncate {
  cursor: default;
}

.suspend-row .rgt-cell-inner{
  color: var(--bs-gray-500);
}

.rgt-cell-linkHover:hover{
  color: var(--bs-body-color);
}