.sidebar-menu {
    padding: 0;
    flex-direction: column;
    grid-area: sidebar;
    overflow-y: auto;
    --bs-navbar-color: var(--light-color);
    transition: all 0.3s ease-in-out;
    --toastify-toast-width: 250px;
    --sidebar-logo-height: 22px;
    --sidebar-logo-width: 175px;

}


.sidebar-menu-maindiv {
    height: calc(100vh - 97px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
}

.sidebar-menu-maindiv.selectClient {
    height: calc(100vh - 137px);
}

.sidebar-menu-maindiv.sideBarShow {
    height: calc(100vh - (28px + var(--sidebar-logo-height)));
}

.sidebar-menu-maindiv.selectClient.sideBarShow {
    height: calc(100vh - (74px + var(--sidebar-logo-height)));
}

.sidebar-menu-maindiv.selectClient.selectServer.sideBarShow {
    height: calc(100vh - (122px + var(--sidebar-logo-height)));
}


.sidebar-logo-header {
    display: flex;
    height: var(--sidebar-logo-height);
    width: var(--sidebar-logo-width);
}

.navbar-brand,
.sidebar-menu-brand {
    margin-right: 0;
}

.sidebar-menu-collapse.collapse {
    display: flex !important;
    flex-flow: column;
    align-items: stretch;
}

.sidebar-menu-nav .sidebar-menu-nav-link,
.sidebar-menu-nav .sidebar-menu-sub-toggle,
.footer-button-navbar {
    padding-left: 0.25em !important;
    padding-right: 0.25em !important;
}

.sidebar-menu-nav-icon {
    width: 3.5rem;
    margin-bottom: 0.4rem;
}

.sidebar-menu-nav .sidebar-menu-nav-link,
.sidebar-menu-nav .sidebar-menu-sub-toggle {
    height: 40px;
    align-items: center;
}

.sidebar-menu-light .sidebar-menu-nav .sidebar-menu-nav-link.active,
.sidebar-menu-light .sidebar-menu-nav .active.sidebar-menu-sub-toggle {
    color: var(--light-active-color);
}

.sidebar-menu-light .sidebar-menu-nav .sidebar-menu-nav-link,
.sidebar-menu-light .sidebar-menu-nav .sidebar-menu-sub-toggle,
.footer-button-navbar {
    color: var(--bs-navbar-color)
}

.footer-button-navbar:hover .footer-button-icon svg {
    color: var(--light-active-color);
}

.dropup .dropdown-toggle::after {
    display: none;
}

.dropdown-item {
    color: var(--color-primary) !important;
    display: flex;
    align-items: center;
}

.dropdown-item:hover {
    background-color: var(--light-hover-bg);
}

.dropdown-item.active {
    color: var(--light-active-color) !important;
    background-color: var(--light-active-bg);
}

#dropdown-button-navbar {
    width: 100%;
    padding-left: 0.25em;
    padding-right: 0.25em;
    margin-bottom: 0.25em;
    display: flex;
    align-items: center;
}

#dropdown-button-navbar .footer-button-icon {
    width: 3.5rem;
}

#dropdown-button-navbar:hover .footer-button-icon svg {
    color: var(--light-active-color);
}

.footer-button-icon {
    width: 3.5rem;
}

.dropdown-menu {
    position: fixed !important;
    // inset: auto auto 3px 5px !important;
}

.sidebar-menu-nav-title {
    width: 80%;
    text-align: start;
    color: var(--light-color);
}

.sidebar-menu-nav-title:hover {
    color: var(--light-hover-color)
}

.icon-navbar-dropdown {
    color: var(--light-color);
}

.dropdown-divider {
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-divider-bg: rgba(0, 0, 0, 0.175);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
}

.sidebar-menu-nav-link:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

.sidebar-menu-body {
    padding: 0 0.2rem 0 0.2rem;
}

.sidebar-menu-body .footer-button-navbar.active svg {
    color: var(--bs-primary) !important;
}

.sidebar-menu-toggle:focus {
    box-shadow: unset !important;
    border-color: var(--light-focus-shadow-rgb) !important;
    box-shadow: var(--light-active-shadow) !important;
    outline: 0;
}

.sidebar-menu-toggle:hover {
    background-color: var(--light-hover-bg) !important;
}

.sidebar-menu-toggle {
    margin-top: 3.2px;
    margin-bottom: 3.2px;
    font-size: unset !important;
}

.sidebar-menu-header {
    padding-top: 0.5rem;
    padding-bottom: 0;
    min-height: calc(26px + var(--sidebar-logo-height));
}

.sidebar-menu-collapse:not(.show) .sidebar-menu-header {
    justify-content: center !important;
}

.sidebar-menu-header {
    align-items: flex-end !important;
}

.logomin {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
    margin-top: 0.15rem
}

.logomin svg {
    margin: 4px;
    margin-left: 0px;
    height: 45px;
    width: auto;
}

.logomin.sidebar {
    opacity: 0;
    visibility: hidden;
}

.sidebar-menu-header .collapsed {
    margin-top: 50px;
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
    --bs-offcanvas-width: 490px;
    --bs-offcanvas-bg: var(--bs-light)
}

.sidebar-menu-toggle .sidebar-menu-toggle-icon {
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' height='200px' width='200px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000b3' d='M17 4H3V6H17V4ZM13 11H3V13H13V11ZM17 18H3V20H17V18ZM22.0104 8.81412L20.5962 7.3999L16 11.9961L20.5962 16.5923L22.0104 15.1781L18.8284 11.9961L22.0104 8.81412Z'%3E%3C/path%3E%3C/svg%3E") !important;
}

.sidebar-menu-toggle.collapsed .sidebar-menu-toggle-icon {
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' height='200px' width='200px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000000b3' d='M17 4H3V6H17V4ZM13 11H3V13H13V11ZM17 18H3V20H17V18ZM15.9896 8.81412L17.4038 7.3999L22 11.9961L17.4038 16.5923L15.9896 15.1781L19.1716 11.9961L15.9896 8.81412Z'%3E%3C/path%3E%3C/svg%3E") !important;
}

.bin:hover {
    fill: var(--bs-red);
    cursor: pointer;
}

.memoCount {
    position: absolute;
    bottom: 5px;
    left: 40px;
    background-color: var(--color1);
    padding: 3px;
    border-radius: 50%;
    min-width: 10px;
    min-height: 10px;
}

.badge {
    background-color: var(--color1);
    border-radius: 50%;
    color: var(--bs-primary);
}

@media (max-width: 576px) {

    .sidebar-menu-collapse.collapse.collapse-horizontal {
        padding-top: 50px;
    }

    .logomin.sidebar {
        opacity: 1;
        visibility: visible;
    }

    .sidebar-menu-maindiv.selectClient.selectServer.sideBarShow,
    .sidebar-menu-maindiv.selectClient.sideBarShow,
    .sidebar-menu-maindiv.selectClient.selectServer,
    .sidebar-menu-maindiv.selectClient {
        height: calc(100dvh - 95px);
    }

    .tooltip.show {
        opacity: 0;
    }

}