.device-plate {
    border: 1px solid #C2C2C2;
    border-radius: 3px;
    display: inline;
    padding: 1px 2.5px;
    color: #7A7A7A;
}

.deviceView td {
    padding: 0.15rem 0.15rem;
    border: unset;
}

.deviceView{
    padding-left: 1.5rem;
}